import dayjs from "dayjs";

import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekYear from "dayjs/plugin/weekYear";
import { getToken } from "../helpers";
import Resizer from "react-image-file-resizer";
import {Button, Checkbox, Drawer, List, message, InputNumber, Alert, Modal} from "antd";
import React, { useEffect, useState } from "react";
import { SaveOutlined, SyncOutlined } from "@ant-design/icons";
import { useSwitch } from "../pages/SwitchContext";


dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

export const getAPIurl = () => {
  const clientUrl = window.location.href;
  if (clientUrl.includes("localhost")) {
    //return "https://buettner-api.valest-app.de/api";
    return "http://localhost:1339/api";
  } else {
    const extractedPart = clientUrl.match(/https:\/\/(.*?)\.valest-app/);
    if (extractedPart && extractedPart.length > 1) {
      return "https://" + extractedPart[1] + "-api.valest-app.de/api";
    } else {
      // Handle case where URL doesn't match expected pattern
    }
  }
}

export const isAllowedDomain = () => {
  if (typeof window !== "undefined") {
    const hostname = window.location.hostname;
    return hostname === "localhost" || hostname === "buettner.valest-app.de";
  }
  return false;
};

export const validatePasswordSecurity = (password) => {
  const minLength = 12;
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasNumbers = /[0-9]/.test(password);
  const hasSpecialChars = /[!@#\$%\^\&*\)\(+=._-]/.test(password);

  if (password.length < minLength) {
    return "Das Passwort muss mindestens 12 Zeichen lang sein.";
  }
  if (!hasUpperCase) {
    return "Das Passwort muss mindestens einen Großbuchstaben enthalten.";
  }
  if (!hasLowerCase) {
    return "Das Passwort muss mindestens einen Kleinbuchstaben enthalten.";
  }
  if (!hasNumbers) {
    return "Das Passwort muss mindestens eine Zahl enthalten.";
  }
  if (!hasSpecialChars) {
    return "Das Passwort muss mindestens ein Sonderzeichen enthalten.";
  }
  return null;  // No errors, password is valid
}

export const LoadingScreen = () => {
  return (
    <div className="loadingScreen">
      <div>
        <SyncOutlined spin /> Daten werden laden...
      </div>
    </div>
  );
};
export const SaveIcon = ({ saveIcon }) => {
  const key = "updatable";

  useEffect(() => {
    if (saveIcon) {
      // Show loading message when saveIcon is true
      message.loading({ content: "Daten werden gespeichert...", key });
    } else {
      // Show success message when saveIcon is false
      message.success({
        content: "Erfolgreich gespeichert",
        duration: 2,
        key,
      });
    }

    // Hide the message after 2 seconds
    // const hideMessage = setTimeout(() => {
    //     message.destroy(key);
    // }, 2000);

    // Clear the timeout when the component is unmounted or when the saveIcon state changes
    // return () => clearTimeout(hideMessage);
  }, [saveIcon]);

  return (
    <div>
      {/* You can customize the content or style of the div as needed */}
    </div>
  );
};

export const getObjectSize = (obj) => Object.keys(obj).length;

export const checkAndSetGermanDateFormat = (item) => {
  const parsedDate = new Date(item);
  if (
    !isNaN(parsedDate) &&
    !isNaN(Date.parse(item)) &&
    item === parsedDate.toISOString().split("T")[0]
  ) {
    //Falls Datum, dann als Datum behandeln
    const parsedDate = dayjs(item);

  // Datum im Format DD.MM.YYYY formatieren
   // return item;
    return dayjs(item)
  } else {
    return item;
  }
};

export const checkAndSetUSFormat = (item) => {
    // Überprüfen, ob es sich um ein deutsches Datum handelt
    const dateRegex = /^\d{2}\.\d{2}\.\d{4}$/;
    if (dateRegex.test(item)) {
      // Falls deutsches Datum, dann auf amerikanisches transformieren
      const [day, month, year] = item.split(".");
      return `${year}-${month}-${day}`;
    }

    // Überprüfen, ob es sich um eine Zahl mit Komma handelt
    const numberRegex = /^-?\d+(?:\.\d{3})*(?:,\d+)?$/;
    if (numberRegex.test(item)) {
      // Falls Zahl mit Komma, dann in englisches Format umwandeln
      const formattedNumber = item.replace(/\./g, 'X').replace(/,/g, '.').replace('X', ',');
      return formattedNumber;
    }

    return item;

};
export const getInitialValuesFormular = (dataContent) => {
  const initialValues = {};
  // Recursive function to build the initialValues object
  const buildInitialValues = (obj, prefix = "") => {
    for (const key in obj) {
      if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
        // JSON
        // If the property is an object (nested), recursively build its values
        buildInitialValues(obj[key], `${prefix}${key}__`);
        //} else if (typeof obj[key] === "object" && !Array.isArray(obj[key])) { // JSON
      } else if (Array.isArray(obj[key])) {
        // component (component or multi-select or multi-select
        // If multi-select
        if (obj[key].every((item) => typeof item === "string")) {
          initialValues[`${prefix}${key}`] = obj[key];
        } else {
          // If the property is a component, iterate through its elements and build values
          obj[key].forEach((item) => {
            if (typeof item === "object") {
              // If the array element is an object, recursively build its values
              buildInitialValues(item, `${prefix}${key}__${item.id}__`);
            } else {
              // Otherwise, add the array element to initialValues
              initialValues[`${prefix}${key}__${item.id}`] = item;
               initialValues[`${prefix}${key}__${item.id}`] = checkAndSetGermanDateFormat(item);
            }
          });
        }
      } else {
        // Otherwise, add the property to initialValues
        initialValues[`${prefix}${key}`] = checkAndSetGermanDateFormat(obj[key]);
      }
    }
  };
  // Start building initialValues with dataContent.AllgemeineAngabenGutachten
  buildInitialValues(dataContent);
  return initialValues;
};

export const changeMultiSelectValues = (initValues, value, checked) => {
  let newValues;
  if (checked === false) {
    newValues = initValues.filter((item) => item !== value);
  } else {
    // Check if there are initValues
    if (!initValues) {
      newValues = [value]; // Create a new array with the added value;
      // Check if the value already exists in initValues
    } else if (!initValues.includes(value)) {
      // Use spread operator to create a new array with the added value
      newValues = [...initValues, value];
    } else {
      // If the value already exists, keep the original array
      newValues = initValues;
    }
  }
  return newValues;
};

export const updateDataContent = (
  inputName,
  inputValue,
  data,
  updateAction
) => {

  const parts = inputName.split("__");
  let current = data;
  let currentItemID;

  for (let i = 0; i < parts.length - 1; i++) {
    const part = parts[i];
    if (current[part] && isNaN(part)) {
      current = current[part];
    } else {
      currentItemID = part;
    }
  }

  const propertyName = parts[parts.length - 1];
  if (updateAction === "deleteComponent") {
    const pathSegments = inputName.split(".");
    for (const segment of pathSegments) {
      if (current.hasOwnProperty(segment)) {
        current = current[segment];
      }
    }

    if (Array.isArray(current)) {
      current.splice(inputValue, 1);
    }
  } else if (updateAction === "updateByID") {
    if (currentItemID === "undefined") {
      //Neue Komponente, hat noch keine ItemID
      const newObj = {};
      newObj[propertyName] = inputValue;
      current.push(newObj);
    } else {
      //Bearbeite bestehende
      // Find the object with the matching ID in your array of objects
      const itemToUpdate = current.find((item) => item.id == currentItemID);

      if (itemToUpdate) {
        // Update the property of the found object
        itemToUpdate[propertyName] = checkAndSetUSFormat(inputValue);
      }
    }
  } else {
    current[propertyName] = checkAndSetUSFormat(inputValue);
  }
  return { ...data }; // Return a shallow copy of the original data object with the updated value
};

export const locationSearch = (
  autocompleteService,
  value,
  setLocationOptions
) => {
  if (autocompleteService) {
    autocompleteService.getPlacePredictions(
      { input: value, region: "de-DE" },
      (predictions, status) => {
        if (status === "OK") {
          setLocationOptions(predictions);
        } else {
          setLocationOptions([]);
        }
      }
    );
  }
};

// Sende einen POST-Request an deine Strapi-API, um den neues Bewertungsobjekt zu erstellen
export const postBewertungsobjekt = async (gutachtenID, duplicateID=0) => {
  try {
    // Create new Bewertungsobjekt
    let BObody;
    if(duplicateID != 0) {

      const responseDuplicatedBewertungsobjekt = await fetch(
          `${getAPIurl()}/helper/acl/bewertungsobjekt/${duplicateID}?populate=deep`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
      );
      const responseDuplicatedBewertungsobjektBody = await responseDuplicatedBewertungsobjekt.json()
      BObody = {
        data: {}
      }
      BObody.data = responseDuplicatedBewertungsobjektBody;
      delete BObody.data.id;
      delete BObody.data.createdAt;
      BObody.data.Wertermittlung.Berechnungsexcel = null; //Set attachment to zero (because upload can not be duplicated)
    } else {
      BObody = {
        data: {
          Wertermittlung: {
            Verkehrswert: {},
          },
          Gebaeude_AA: {
            Einheiten_KGA: {},
            Gebauede_Aussenanlage: {},
            MieteUndKosten: {},
          },
        },
      };
    }
    const responseBO = await fetch(
      `${getAPIurl()}/helper/acl/bewertungsobjekt`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(BObody.data),
      }
    );

    if (!responseBO.ok) {
      console.log("Fehler beim Erstellen des Eintrags.");
    }

    const responseBody = await responseBO.json();
    const BOID = responseBody.id;
    const connectionID = {
      data: {
        bewertungsobjekts: {
          connect: [BOID],
        },
      },
    };

    // Add connection to gutachtens
    const response = await fetch(
      `${getAPIurl()}/helper/acl/gutachten/${gutachtenID}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(connectionID.data),
      }
    );

    if (!response.ok) {
      console.log("Fehler beim Hinzufügen der Verbindung.");
    }
    return { id: BOID };
  } catch (error) {
    console.error("Fehler:", error);
    return { error: "Fehler beim Erstellen des Eintrags." };
  }
};

export const openS3File = async (filename) => {
  const response = await fetch(
    `${getAPIurl()}/helper/files/${filename}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
  const responsedata = await response.json();
  window.open(responsedata.signedUrl, "_blank");
};

export const createAuftragExecute = async (userKonfiguration,navigate,duplicatedAuftrag= 0) => {
  try {
    // Create Auftragsnummer (Ordernumber)
      let newAuftragsnummer;
      let prevAuftragsnummer;
      let gutachtenData;
      const responseAuftragsnummer = await fetch(
          `${getAPIurl()}/helper/acl/auftrag/?sort=createdAt:asc&pagination[limit]=1`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
      );
      const responseBody = await responseAuftragsnummer.json();
      if (responseBody[responseBody.length - 1]) {
        if (userKonfiguration.OrderNumeration === "PerYear") {
          const yearPreviousEntry = new Date(
              responseBody[responseBody.length - 1].createdAt
          ).getFullYear();
          if (yearPreviousEntry < new Date().getFullYear()) {
            //New Year beginning
            prevAuftragsnummer = new Date().getFullYear() + "-0";
          } else {
            prevAuftragsnummer =
                responseBody[responseBody.length - 1].Auftragsnummer; //Based on previous entry
          }
        } else {
          // Numeration onGoing (always based on previous entry)
          prevAuftragsnummer =
              responseBody[responseBody.length - 1].Auftragsnummer;
        }
      } else {
        prevAuftragsnummer = new Date().getFullYear() + "-0";
      }
      if (prevAuftragsnummer && prevAuftragsnummer.includes("-")) {
        const parts = prevAuftragsnummer.split("-"); // Get parts of previous order number
        if (!isNaN(parts[1])) {
          // Check if second Number
          newAuftragsnummer = parseInt(parts[1]) + 1; // Add +1 (Based on Auftragsnummer of previous entry
        } else {
          newAuftragsnummer =
              responseBody[responseBody.length - 1].id + 1; // Based on entryId of previous entry (Not on content!!!)
        }
      } else {
        newAuftragsnummer =
            responseBody[responseBody.length - 1].id + 1; // Based on entryId of previous entry (Not on content!!!)
      }

      // Check if duplicated
      let newData;

      if(duplicatedAuftrag != 0) {

        const responseDuplicatedAuftrag = await fetch(
            `${getAPIurl()}/helper/acl/auftrag/${duplicatedAuftrag}?populate=deep`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
              },
            }
        );
        const responseDuplicatedAuftragBody = await responseDuplicatedAuftrag.json()
        newData = {
          data: {}
        }

        newData.data = responseDuplicatedAuftragBody;

        newData.data.UN_Auftrag.Auftrag_Doc = null; //Set attachment to zero (because upload can not be duplicated)
        newData.data.UN_Auftrag.Vollmacht_Doc = null; //Set attachment to zero (because upload can not be duplicated)
        delete newData.data.createdAt;
        delete newData.data.id;
        gutachtenData = newData.data.gutachtens;
        delete newData.data.gutachtens;

        newData.data.Auftragsnummer = new Date().getFullYear() + "-" + newAuftragsnummer;
      } else {
        // Daten für den neuen Eintrag
        newData = {
          data: {
            Angebot: {},
            UN_Auftrag: {},
            Auftragsnummer: new Date().getFullYear() + "-" + newAuftragsnummer,
          },
        };
      }

      // Sende einen POST-Request an deine Strapi-API, um den neuen Eintrag zu erstellen
      const response = await fetch(`${getAPIurl()}/helper/acl/auftrag/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(newData.data),
      });

      if (response.ok) {
        const responseBody = await response.json();
        const newUrl = `/auftrag/${responseBody.id}`;
        if(duplicatedAuftrag != 0) {
          let gutachtenPromises = gutachtenData.map(gutachten => {
            const gutachtenId = gutachten.id;
            // Duplicate also connected Gutachten, if duplicated Auftrag
            return postGutachten(responseBody.id, gutachtenId).then((response) => {
              // Duplicate also connected Bewertungsobjekt, if duplicated Gutachten
              const promises = gutachten.bewertungsobjekts.map(BO => {
                return postBewertungsobjekt(response.id, BO.id);
              });
              return Promise.all(promises);
            });
          });
          //If everything is finish => Navigate to Auftrag
          Promise.all(gutachtenPromises).then(() => {
            navigate(newUrl);
            window.location.reload();
          });
        } else {
          postGutachten(responseBody.id).then(() => {
            navigate(newUrl);
            window.location.reload();
          });
        }

      } else {
        // Behandle den Fall, wenn der POST-Request fehlschlägt
        console.error("Fehler beim Erstellen des Eintrags.");
      }
  } catch (error) {
    // Behandle andere Fehler, die auftreten könnten
    console.error("Fehler beim Erstellen des Eintrags:", error);
  }
};

// Sende einen POST-Request an deine Strapi-API, um den neues Gutachten zu erstellen
export const postGutachten = async (auftragID,duplicateID=0) => {
  try {
    let gutachtenbody;
    if(duplicateID != 0) {
      const responseDuplicatedGutachten = await fetch(
          `${getAPIurl()}/helper/acl/gutachten/${duplicateID}?populate=deep`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
      );
      const responseDuplicatedGutachtenBody = await responseDuplicatedGutachten.json()
      gutachtenbody = {
        data: {}
      }
      gutachtenbody.data = responseDuplicatedGutachtenBody;
      delete gutachtenbody.data.bewertungsobjekts;
      delete gutachtenbody.data.id;
      //Unset attachments
      gutachtenbody.data.AllgAng.Unterlagen = [];
      Object.keys(gutachtenbody.data.Anlagen).forEach(key => {
        gutachtenbody.data.Anlagen[key] = [];
      });
    } else {
      // Create new Gutachten
      gutachtenbody = {
        data: {
          AllgAng: {
            AllgAng_Detail: {},
          },
          Lage: {
            Wirtschaftsdaten: {},
          },
          Anlagen: {},
          Grundstueck: {},
          Gebaeude_AA: {
            Einheiten_KGA: {},
            Gebauede_Aussenanlage: {},
            MieteUndKosten: {},
          },
          Wertermittlung: {
            Verkehrswert: {},
          },
        },
      };
    }

    const responseGutachten = await fetch(
      `${getAPIurl()}/helper/acl/gutachten/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(gutachtenbody.data),
      }
    );

    if (!responseGutachten.ok) {
      console.log("Fehler beim Erstellen des Eintrags.");
    }

    const responseBody = await responseGutachten.json();
    const gutachtenID = responseBody.id;
    const connectionID = {
      data: {
        gutachtens: {
          connect: [gutachtenID],
        },
      },
    };
    // Add connection to gutachtens
    const response = await fetch(
      `${getAPIurl()}/helper/acl/auftrag/${auftragID}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(connectionID.data),
      }
    );

    if (!response.ok) {
      console.log("Fehler beim Hinzufügen der Verbindung.");
    }
    return { id: gutachtenID };
  } catch (error) {
    console.error("Fehler:", error);
    return { error: "Fehler beim Erstellen des Eintrags." };
  }
};

export const resizeFile = (file, extension) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1500,
      1500,
      extension,
      70,
      0,
      (blob) => {
        resolve(blob);
      },
      "blob"
    );
  });

export const uploadFile = async (fileVar, onlyImages = false, auftragID) => {
  const { file } = fileVar;
  // Only allowed file types (Backend check is directly in S3)
  if (
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "application/pdf" ||
    file.type ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
    file.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      file.type === "application/vnd.ms-excel.sheet.macroEnabled.12" ||
    file.type === "application/vnd.ms-excel.sheet.macroenabled.12"
  ) {
    if (
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      onlyImages == false
    ) {
      const formData = new FormData();
      let resizedFile;

      if (file.type === "image/jpeg" || file.type === "image/png") {
        resizedFile = await resizeFile(file, file.type);
      } else {
        resizedFile = file;
      }
      formData.append(`files`, resizedFile, auftragID + "-" + file.name);

      try {
        const response = await fetch(`${getAPIurl()}/helper/acl/upload`, {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        });

        if (response.ok) {
          return await response.json();
        } else {
          return `Request failed with status ${response.status}`;
          console.log("Request failed with status", response.status);
        }
      } catch (error) {
        return error;
      }
    }
  } else {
    console.log("Filetyp "+file.type+ " nicht erlaubt");
  }
};

export const setInitialUploadForms = (
  updatedUploadForms,
  files,
  name,
  singleComponent = false
) => {
  updatedUploadForms[name] = {};

  if (singleComponent) {
    if (files && files !== "-") {
      updatedUploadForms[name] = {
        file: files,
        visible: false,
      };
    } else {
      updatedUploadForms[name] = {
        file: "",
        visible: true,
      };
    }
  } else {
    // Repeatable Component
    for (const key in files) {
      if (files.hasOwnProperty(key)) {
        const file = files[key];
        let fileContainer = null;

        for (const keyFile in file) {
          if (file.hasOwnProperty(keyFile)) {
            const value = file[keyFile];

            if (typeof value === "object" && value !== null) {
              fileContainer = value;
            }
          }
        }

        updatedUploadForms[name][file.id] = {
          file: fileContainer ? fileContainer : "",
          visible: !fileContainer,
        };
      }
    }
  }

  return updatedUploadForms;
};

export const handleCreateKontakt = async (navigate,auftragID) => {
  try {
    // Daten für den neuen Eintrag
    const newData = {
      data: {},
    };
    // Sende einen POST-Request an deine Strapi-API, um den neuen Eintrag zu erstellen
    const response = await fetch(`${getAPIurl()}/helper/acl/kontakte`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify(newData),
    });

    if (response.ok) {
      const responseBody = await response.json();
      if(auftragID) {
        navigate(`/kontakte/${responseBody.id}/${auftragID}`);
      } else {
        navigate(`/kontakte/${responseBody.id}/0`);
      }
    } else {
      // Behandle den Fall, wenn der POST-Request fehlschlägt
      console.error("Fehler beim Erstellen des Eintrags.");
    }
  } catch (error) {
    // Behandle andere Fehler, die auftreten könnten
    console.error("Fehler beim Erstellen des Eintrags:", error);
  }
};

export const getKontakte = async (filter_active = false) => {
  try {
    let url = ``;
    let filters = ``;

    if(filter_active == true){
      filters = `&filters[archived][$eq]=0`;
    }
    url = `${getAPIurl()}/helper/acl/kontakte?fields[0]=KontakttypV&fields[1]=Kontaktname`+filters;

    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    const dataContainer = await response.json();
    let returnValue = {};
    dataContainer.map((entry) => {
      if (entry.Kontaktname !== null) {
        returnValue[entry.id] = entry;
      }
      return null; // Map function expects a return value, use null if not returning anything meaningful
    });
    return returnValue;
  } catch (error) {
    console.log(error);
    message.error("Error while fetching profiles!");
  }
};

const handleScrollURL = () => {
  const sections = document.querySelectorAll(".scrollable-section");
  let currentSectionId = "";
  sections.forEach((section) => {
    const rect = section.getBoundingClientRect();
    if (rect.top >= -100 && rect.top <= 100) { //
      currentSectionId = section.id;
    }
  });
  if (currentSectionId) {
    // Get the current URL
    var currentUrl = window.location.href;

    // Check if there is already a hash in the URL
    var newUrl;
    if (currentUrl.includes("#")) {
      // Replace the current hash with the new anchor
      newUrl = currentUrl.replace(/#.*$/, "#" + currentSectionId);
    } else {
      // Append the new anchor to the URL
      newUrl = currentUrl + "#" + currentSectionId;
    }

    // Update the URL without jumping
    window.history.replaceState(null, null, newUrl);
  }
};

//Limite execution of onscroll-Events
export const throttle = (func, limit) => {
  let inThrottle;
  return () => {
    if (!inThrottle) {
      func();
      inThrottle = true;
      setTimeout(() => (inThrottle = false), limit);
    }
  };
};
export const optimizedHandleScroll = throttle(handleScrollURL, 50); // Adjust the limit as needed

export const useModals = () => {
  const [modals, setModals] = useState([]);
  const [selectedTextBausteine, setSelectedTextBausteine] = useState(null); // Add a state for the value

  const handleOpenTextbausteine = (field, label, category) => {
    const newModal = { field, label, category, open: true };
    setModals((prevModals) => [...prevModals, newModal]);
  };

  const handleCloseTextbausteine = (index, newTextBaustein) => {
    setModals((prevModals) => {
      const updatedModals = [...prevModals];
      updatedModals[index].open = false;
      return updatedModals;
    });
    // Set the value you want to pass to the component
    if (newTextBaustein && typeof newTextBaustein === "string") {
      setSelectedTextBausteine(newTextBaustein);
    }
  };

  const renderModals = () => {
    return modals.map((modal, index) => (
      <TextbausteineModal
        key={index}
        field={modal.field}
        label={modal.label}
        category={modal.category}
        open={modal.open}
        onClose={(newTextBaustein) =>
          handleCloseTextbausteine(index, newTextBaustein)
        }
      />
    ));
  };
  return {
    modals,
    handleOpenTextbausteine,
    handleCloseTextbausteine,
    renderModals,
    selectedTextBausteine,
  };
};

export const TextbausteineModal = ({
  field,
  label,
  category,
  open,
  onClose,
}) => {
  const [fetchedData, setFetchedData] = useState([]);
  const [selectedTextbausteine, setSelectedTextbausteine] = useState([]);
  const { isSwitchOn, toggleSwitch } = useSwitch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${getAPIurl()}/textbausteines?filters[Kategorie][$eq]=${category}&sort=rank`, //
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );

        if (!response.ok) {
          console.log("Network response was not ok");
        }

        const dataContainer = await response.json();
        const newData = dataContainer.data
          .filter((entry) => entry.attributes.Textbaustein_Satz !== null)
          .map((entry) => entry.attributes);

        // Update the state, triggering a re-render
        setFetchedData(newData);
      } catch (error) {
        console.error(error);
        message.error("Error while fetching profiles!");
      }
    };

    if (open) {
      fetchData();
    }
  }, [open]);

  const handleCheckboxChange = (textbausteinSatz) => {
    const updatedSelectedTextbausteine = selectedTextbausteine.includes(
      textbausteinSatz
    )
      ? selectedTextbausteine.filter((item) => item !== textbausteinSatz)
      : [...selectedTextbausteine, textbausteinSatz];

    setSelectedTextbausteine(updatedSelectedTextbausteine);
  };

  const handleInsert = () => {
    // Hier kannst du die ausgewählten Textbausteine direkt in das entsprechende Textfeld einfügen
    const textBox = document.getElementById(field);
    if (textBox) {
      const selectedTextbausteineContent = selectedTextbausteine.join("\n");
      const currentContent = textBox.value || "";
      textBox.value = currentContent
        ? `${currentContent}\n${selectedTextbausteineContent}`
        : selectedTextbausteineContent;
      console.log(textBox.value);
      onClose(textBox.value); // Close the Drawer
    } else {
      console.log("Textfeld nicht gefunden");
      onClose(); // Close the Drawer
    }
  };

  return (
    <Drawer
      title={`Textbausteine`}
      placement="right"
      onClose={onClose}
      open={open}
      width={500}
      className={`${isSwitchOn ? "text-white" : "text-black"}  ${
        isSwitchOn ? "drawerOuter" : ""
      }`}
    >
      <div className={`${isSwitchOn ? "bg-black" : "bg-white"}`}>
        <p>
          <b
            className={`${isSwitchOn ? "text-white" : "text-black"}`}
          >{`Textfeld: ${label}`}</b>
        </p>

        {/* Conditionally render the List if there is data */}
        {fetchedData.length > 0 ? (
          <List
            bordered
            dataSource={fetchedData}
            renderItem={(item) => (
              <List.Item
                className={`${isSwitchOn ? "whiteText" : "blackText"}`}
              >
                <Checkbox
                  onChange={() => handleCheckboxChange(item.Textbaustein_Satz)}
                >
                  <span className={`${isSwitchOn ? "whiteText" : "blackText"}`}>
                    {item.Textbaustein_Satz}
                  </span>
                </Checkbox>
              </List.Item>
            )}
          />
        ) : (
          <p>Loading...</p>
        )}

        <br />

        <Button
          type="primary"
          onClick={handleInsert}
          className="bg-[#FF2E3B] mb-[20px] text-[#FFFFFF] ml-[20px] rounded-full buttonHover"
        >
          Markierte Textbausteine einfügen
        </Button>
      </div>
    </Drawer>
  );
};
export const addIcon = (fieldId) => {
  const fieldElement = document.getElementById(fieldId);
  if (fieldElement) {
    fieldElement.classList.add("with-icon");
    fieldElement.innerHTML += `<span class="save-icon"><SaveOutlined /></span>`;
  }
};

export const downloadRenderFile = async (
  type,
  file_type,
  auftragID,
  gutachtenIDorAnforderung
) => {
  try {

    const fileResponse = await fetch(
      `${getAPIurl()}/helper/render-docx/${type}/${auftragID}/${gutachtenIDorAnforderung}?file_type=${file_type}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );

    const blob = await fileResponse.blob();

    // Create a Blob URL for the file
    const url = window.URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement("a");
    link.href = url;

    // Set the download attribute with the desired file name
    if (gutachtenIDorAnforderung) {
      link.download = type + "-" + auftragID + "-" + gutachtenIDorAnforderung;
    } else {
      //Vollmacht (or Auftrag)
      link.download = type + "-" + auftragID;
    }

    // Append the link to the document
    document.body.appendChild(link);

    // Trigger a click on the link to initiate the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);

    // Revoke the Blob URL to free up resources
    window.URL.revokeObjectURL(url);

  } catch (error) {
    console.error("Error downloading file:", error);
  }
};

// Format InputNumber format DE <-> US
export const MyInputNumber = ({ value, ...props }) => {
  const decimalSeparator = (1.1).toLocaleString().substring(1, 2); // Bestimme den Dezimaltrenner für das aktuelle System

  const customFormatter = (value) => {
    if (value || value === 0) {
      const formattedValue = new Intl.NumberFormat('de-DE', { maximumFractionDigits: 2 }).format(value);
      return formattedValue;
    }
    return '';
  };

  const customParser = (value) => {
    if (value || value === 0) {
      // Entferne alle Nicht-Ziffern, außer Komma und Minus
      const cleanedValue = value.replace(/[^\d,-]/g, '').replace(/,/g, '.');
      // Ersetze Komma durch Punkt für das Parsen
      let parsedValue = 0;
      if (cleanedValue.includes('.') && cleanedValue.split('.')[1].length === 2) {
       parsedValue = parseFloat(cleanedValue).toFixed(2);
      } else {
        parsedValue = parseFloat(cleanedValue);
      }
      // Wenn die Dezimalstelle eine Null ist, füge sie manuell hinzu
      const decimalIndex = value.indexOf(decimalSeparator);
      if (decimalIndex !== -1 && value.charAt(decimalIndex + 1) === '0') {
        parsedValue = parseFloat(parsedValue.toFixed(2)); // Formatieren auf zwei Nachkommastellen
      }
      return isNaN(parsedValue) ? 0 : parsedValue;
    }
    return 0;
  };

  const handleBlur = (e) => { // Execute onblur to format value before central onblur
    e.target.value = customParser(e.target.value);
  };

  return (
      <InputNumber
          {...props}
          defaultValue={value}
          formatter={customFormatter}
          parser={customParser}
          onBlur={handleBlur}
      />
  );
};

//KM 07.02.2025: Get automated data
export const handleKiDATA = async (form,triggerFocus,cat,textareaId,address,objektart) => {

    const confirmContent = (
        <div>
          Möchten Sie eine Textautomatisierung erstellen? <br/><br/>
          <Alert
              description={<span>Dieser Abruf ist gemäß Ihres Vertrages kostenpflichtig.</span>}
              type="info"
              showIcon
          />
        </div>
    );

  if (address != "") {
    if (objektart == "" || objektart == null) {
      message.error("Objektart (in Allgemeine Angaben) wird benötigt, um Textautomatisierung zu nutzen!");
    } else {

    Modal.confirm({
      title: "Textautomatisierung",
      content: confirmContent,

      onOk: async () => {  // <-- Funktion als async deklarieren

            try {

              const fileResponse = await fetch(  // <-- await hinzufügen
                  `${getAPIurl()}/helper/ki-data/${cat}?address=${address}&objektart=${objektart}`,
                  {
                    headers: {
                      Authorization: `Bearer ${getToken()}`,
                    },
                  }
              );

              const json = await fileResponse.json();  // <-- await hinzufügen

              if (json.success) {
                let content = json.data.html_text;
                if (cat == "mikrolage") {
                  const delimiter = '<br><br><br><br><b>Erläuterungen zur Verkehrsinfrastruktur</b><br>';
                  const parts = content.split(delimiter);
                  set_content("Lage__Beurteilung_Lage", parts[0]);
                  set_content("Lage__Beurteilung_Verkehrslage", parts[1], 100);
                } else {
                  set_content(textareaId, content);
                }

                function set_content(textareaId, content, delay = 0) {
                  content = content.replace(/<b>\s*Erläuterungen\s+zur\s+[^<]+<\/b>\s*<br\s*\/?>/gi, "");
                  content = content.replace(/<br\s*\/?>/gi, "\n");
                  content = content.replace(/<u>/gi, "").replace(/<\/u>/gi, "");

                  setTimeout(() => {
                    form.setFieldsValue({ [textareaId]: content });
                    triggerFocus(textareaId);
                  }, delay);
                }
              } else {
                message.error("Fehler beim Laden der automatischen Daten!");
              }
            } catch (e) {
              console.error(e);
              message.error("Ein unerwarteter Fehler ist aufgetreten!");
            }
      },

      onCancel() {
        console.log("Automatisierung abgebrochen");
      },
    });

    }

  }
}

export const BackToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Show/hide the button based on scroll position
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
      <div>
        {isVisible &&
            <button className="back-to-top-button" onClick={scrollToTop}>
              Nach oben
            </button>
        }
      </div>
  );
};

export const isTextFieldActive = () => {
  return document.activeElement && ['INPUT', 'TEXTAREA'].includes(document.activeElement.tagName);
}